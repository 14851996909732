import './App.less';
import React, {useEffect, useMemo, useState} from 'react';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TokenPocketWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {WalletModalProvider} from '@solana/wallet-adapter-ant-design';
import {clusterApiUrl} from '@solana/web3.js';
import OjOtris from "./component/OjOtris";
import {fetchApi} from "./FetchApi";

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-ant-design/styles.css');


function App() {

  const [aux, setAux] = useState(null)

  useEffect(() => {
    (async () => {
      const aux = await fetchApi.get('/aux')
      setAux(aux)
    })();
  }, []);

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => aux && clusterApiUrl(aux.solanaNetwork), [aux]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => aux && [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({network: aux.solanaNetwork}),
      new TokenPocketWalletAdapter(),
      // new TorusWalletAdapter(),
      // new LedgerWalletAdapter(),
      // new SolletWalletAdapter({network}),
      // new SolletExtensionWalletAdapter({network}),
    ],
    [aux]
  );

  return (
    aux && <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <OjOtris aux={aux}/>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;