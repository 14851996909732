import Tetris from "react-tetris";
import {Col, Modal, Row} from "antd";

let prevState = 'PLAYING';

export function Game({onGameFinish}) {

  function handleState(state, points, reset) {
    if (state === 'LOST' && prevState !== 'LOST') {
      console.warn("Game lost")
      onGameFinish(points)
      Modal.success({
        content: `Your score: ${points}. Press OK to try again`,
        onOk: () => reset()
      })
    }
    prevState = state
    return false
  }

  return <Tetris>
    {({
        HeldPiece,
        Gameboard,
        PieceQueue,
        points,
        linesCleared,
        state,
        reset
      }) => (
      <div>
        <Row gutter={10} justify={'center'}>
          <Col>
            <p>Points: {points}</p>
            <HeldPiece/>
          </Col>
          <Col>
            <p align={'center'}>OjOtris</p>
            <Gameboard/>
            {handleState(state, points, reset)}
          </Col>
          <Col>
            <p>Lines Cleared: {linesCleared}</p>
            <PieceQueue/>
          </Col>
        </Row>
      </div>
    )}
  </Tetris>;
}
