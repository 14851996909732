import {Col, Image, Layout, Row, Select, Table} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import {Game} from "./Game";
import {useWallet} from "@solana/wallet-adapter-react";
import React, {useEffect, useRef, useState} from "react";
import {WalletMultiButton} from "@solana/wallet-adapter-ant-design";
import bs58 from "bs58";
import {fetchApi} from "../FetchApi";

const {Option} = Select;

const {Connection, programs} = require('@metaplex/js');
const {metadata: {Metadata}} = programs;

function OjOtris({aux}) {

  const {publicKey, signMessage} = useWallet();
  const [sessionId, setSessionId] = useState(null)
  const [mint, setMint] = useState(null)
  const [mints, setMints] = useState([])
  const selectMintRef = useRef(null);
  const [scores, setScores] = useState([])

  useEffect(() => {
    (async () => {
      await fetchScores()
      if (publicKey) {
        const connection = new Connection(aux.solanaEnv);
        const metadata = await Metadata.findDataByOwner(connection, publicKey)
        setMints(
          metadata.filter((m) => m.data.creators.findIndex((c) => c.address === aux.creator) !== -1)
        )
      } else {
        setMints([])
        setMint(null)
        setSessionId(null)
      }
    })();
  }, [publicKey]);

  async function fetchScores() {
    const scoresData = await fetchApi.get('/scores')
    const scores = scoresData.map((score, idx) => {
      return {
        idx: idx + 1,
        nft: score.nft.name,
        score: score.value
      }
    })
    setScores(scores)
  }

  async function onMintSelect(value) {
    setSessionId(null)
    const question = await fetchApi.post('/session/create', {wallet: publicKey, mint: value})
    const message = new TextEncoder().encode(question.message);
    const signature = await signMessage(message);
    await fetchApi.post('/session/authenticate', {sessionId: question.sessionId, signature: bs58.encode(signature)})
    setMint(value)
    setSessionId(question.sessionId)
    selectMintRef.current.blur()
  }

  async function onGameFinish(points) {
    await fetchApi.post('/score', {sessionId: sessionId, score: points})
    await fetchScores()
  }

  const scoreColumns = [
    {
      title: '',
      dataIndex: 'idx',
      key: 'idx'
    },
    {
      title: 'NFT',
      dataIndex: 'nft',
      key: 'nft',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    }
  ];

  return (
    <div>
      <Layout>
        <Header style={{background: 'white'}}>
          <Row gutter={10} justify={'end'}>
            <Col>
              {
                mints.length > 0 &&
                <Select placeholder={'Select a mint'} size={'large'} value={mint} onChange={onMintSelect}
                        ref={selectMintRef}>
                  {mints.map(mint => {
                    return <Option value={mint.mint}>{mint.data.name}</Option>
                  })
                  }
                </Select>
              }
            </Col>
            <Col>
              <WalletMultiButton/>
            </Col>
          </Row>
        </Header>
        <Content style={{background: 'white'}}>
          {sessionId &&
            <Row>
              <Col offset={4} span={16}>
                <Game onGameFinish={onGameFinish}/>
              </Col>
              <Col span={4} style={{paddingRight: 50}}>
                <Table dataSource={scores} columns={scoreColumns} pagination={false} size={"small"} bordered/>
              </Col>
            </Row>
          }
        </Content>
      </Layout>
    </div>);
}

export default OjOtris;
